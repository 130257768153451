<template>
  <div class="table_actions">
    <call-tooltip
      placement="top"
      content="Resend">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataPaperIcon"
        @click="clickAction('resend')" />
    </call-tooltip>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallTableSmsLogsActions',
  components: { CallButton },
  props: {
    blocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataPaperIcon: faPaperPlane
    };
  },
  methods: {
    clickAction (action) {
      this.$emit('on-action', action);
    }
  }
};
</script>

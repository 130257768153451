export default {
  data () {
    return {
      dataMixinCallLogFields: [
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th'
        },
        {
          label: 'Date',
          key: 'created_at',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Operator',
          key: 'operator_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Team',
          key: 'team_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Projects',
          key: 'project_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Tasks',
          key: 'task_id',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center'
        },
        {
          label: 'Duration',
          key: 'duration',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Issue',
          key: 'issue_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Comments',
          key: 'comments',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action'
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};

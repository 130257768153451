<template>
  <div class="home">
    <div class="filter-wrapper">
      <div class="link-wrapper">
        <router-link :to="{ name: 'CallsRecords' }">
          Records
        </router-link>
        <router-link :to="{ name: 'CallsLogs' }">
          Logs
        </router-link>
        <router-link
          v-if="computedIsAdmin"
          :to="{ name: 'CallsIssues' }">
          Issues
        </router-link>
        <!-- v-if="computedIsAdmin" -->
        <router-link :to="{ name: 'CallsReport' }">
          Issues Report
        </router-link>
        <call-input
          v-model="dataSearch"
          type-input="search"
          placeholder="Search"
          @input="onSearch">
          <template slot="prefix">
            <font-awesome-icon :icon="dataIconSearch" />
          </template>
        </call-input>
        <v-select
          v-if="!computedIsOperator"
          v-model="dataSelectLastDayValue"
          :options="dataSelectOptionsLastDay"
          :filterable="false"
          :searchable="false"
          :components="{ Deselect: dataComponentDeselect }"
          placeholder="Last Days"
          :class="[
            'call-select call-select-filter',
            dataSelectLastDayValue ? 'call-select--value' : null,
          ]"
          @input="onSelectRefresh">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
        </v-select>
        <v-select
          v-model="dataSelectTeamsValue"
          :options="dataSelectOptionsTeam"
          label="name"
          :components="{ Deselect: dataComponentDeselect }"
          placeholder="All Teams"
          :class="[
            'call-select call-select-filter',
            dataSelectTeamsValue ? 'call-select--value' : null,
          ]"
          @input="onSelectTeams">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Team
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>

        <v-select
          v-if="!computedIsOperator"
          v-model="dataSelectOperatorValue"
          :options="dataSelectOptionsOperator"
          label="name"
          :components="{ Deselect: dataComponentDeselect }"
          placeholder="All Operators"
          :class="[
            'call-select call-select-filter',
            dataSelectOperatorValue ? 'call-select--value' : null,
          ]"
          @input="onSelectRefresh">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Operator
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <v-select
          v-if="!computedIsOperator"
          v-model="dataSelectIssueValue"
          :options="dataSelectOptionsIssues"
          label="name"
          style="height: 38px; max-width: 200px"
          :components="{ Deselect: dataComponentDeselect }"
          placeholder="All Issues"
          :class="[
            'call-select call-select-filter',
            dataSelectIssueValue ? 'call-select--value' : null,
          ]"
          @input="onSelectRefresh">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Issue
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <popover
          v-model="dataShowPopover"
          trigger="click">
          <div style="padding: 6px">
            <div
              v-for="list in filterPhone"
              :key="list.name"
              :class="{ 'is-active': list.active }"
              style="background-color: transparent; margin-bottom: 2px"
              class="call-phone-checkbox-filter">
              <input
                :id="'subscribeNews' + list.name"
                v-model="list.active"
                type="checkbox">
              <label
                class="call-phone-checkbox-filter__title"
                :for="'subscribeNews' + list.name">
                {{ list.name }}
              </label>
            </div>
            <div style="display: flex; min-width: 300px; margin-top: 6px">
              <call-button
                type="danger"
                plain
                full
                style="margin: 0px 2px"
                :icon="dataIconClose"
                @click="clickFilterCancel()">
                &nbsp;Clear Filter
              </call-button>
              <call-button
                type="success"
                full
                style="margin: 0px 2px"
                :icon="dataIconCheck"
                @click="clickFilterSend()">
                &nbsp;Filter
              </call-button>
            </div>
          </div>
          <template #reference>
            <call-button
              :icon="dataIconFilter"
              style="position: relative">
              Filter
              <call-badge
                v-if="computedFilterCount > 0"
                class="call-filter-badge"
                type="danger">
                {{ computedFilterCount }}
              </call-badge>
            </call-button>
          </template>
        </popover>
      </div>
      <div>
        <call-pagination
          :page-value="50"
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        show-empty
        no-local-sorting
        :fields="dataMixinCallLogFields"
        :items="dataCallLogList"
        @sort-changed="onSortTabel"
        @row-hovered="onHover"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-unhovered="onUnHover">
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateFilter }}
        </template>
        <template #cell(task_id)="data">
          <call-badge-table
            :hide-icon="true"
            :type="methodTypeCall(data.item.duration)">
            ID: {{ data.item.task_id }}
          </call-badge-table>
        </template>
        <template #cell(duration)="data">
          {{ data.item.duration | dateCall }}
        </template>
        <template #cell(comments)="data">
          {{ data.item.comments.length }}
          <font-awesome-icon
            :class="
              data.item.comments.length > 0
                ? 'tabel-icon--green'
                : 'tabel-icon--white'
            "
            :icon="dataIconComment" />
        </template>
        <template #cell(actions)="data">
          <call-table-sms-logs-actions
            v-show="data.item.resend_available"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import {
  CALL_GET_LOGS,
  ISSUES_GET,
  TEAMS_GET_ALL,
  USERS_GET_ALL
} from '../../../store/types/actions.types';
import helper from '../../../service/helper';
import CallPagination from '@/components/desktop/CallPagination';
import {
  faCheck,
  faClock,
  faComments,
  faFilter,
  faSearch,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import CallTableSmsLogsActions from '@/components/desktop/CallTableSmsLogsActions';
import selectAllTeams from '../../../mixins/select/selectAllTeams';
import { mapGetters } from 'vuex';
import selectOperators from '../../../mixins/select/selectOperators';
import { ID_ROLE_OPERATOR } from '../../../service/consts';
import cloneDeep from 'lodash/cloneDeep';
import selectReportPeriod from '../../../mixins/select/selectReportPeriod';
import CallButton from '../../../components/common/CallButton';
import Popover from 'vant/lib/popover';
import 'vant/lib/popover/style';
import CallBadge from '@/components/common/CallBadge';
import callLogTable from '../../../mixins/table/callLogTable';
import CallBadgeTable from '../../../components/common/CallBadgeTable';
import debounce from 'lodash/debounce';
import CallInput from '../../../components/common/CallInput';

export default {
  name: 'PageSms',
  components: {
    CallTableSmsLogsActions,
    CallPagination,
    BTable,
    Popover,
    CallBadge,
    CallButton,
    CallInput,
    CallBadgeTable
  },
  mixins: [callLogTable, selectAllTeams, selectOperators, selectReportPeriod],
  data () {
    const filter = {
      dataIconFilter: faFilter,
      dataIconClose: faTimes,
      dataIconSearch: faSearch,
      dataShowPopover: false,
      filterPhone: [
        {
          active: false,
          name: 'Successful',
          key: 'success'
        },
        {
          active: false,
          name: 'Cancelled',
          key: 'cancelled'
        },
        {
          active: false,
          name: 'No Answer',
          key: 'noanswer'
        },
        {
          active: false,
          name: 'Rejected',
          key: 'rejected'
        },
        {
          active: false,
          name: 'Invalid Number',
          key: 'invalid_number'
        },
        {
          active: false,
          name: 'Not Available',
          key: 'not_available'
        }
      ]
    };
    const issues = {
      dataSelectIssueValue: null,
      dataSelectOptionsIssues: []
    };

    return {
      dataIconCheck: faCheck,
      dataIconError: faTimes,
      dataIconClock: faClock,
      dataIconComment: faComments,
      dataObjPagination: {},
      dataCallLogList: [],
      dataDesc: null,
      dataOrderBy: null,
      dataSearch: null,
      ...filter,
      ...issues
    };
  },
  computed: {
    ...mapGetters({
      computedIsOperator: 'auth/isOperator',
      computedIsModerator: 'auth/isModerator',
      computedCurrentUser: 'auth/currentUser',
      computedIsAdmin: 'auth/isAdmin'
    }),
    computedFilterCount () {
      return this.filterPhone.filter((item) => item.active).length;
    }
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = 50;

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(
            JSON.parse(this.$route.query[_filterKey])
          );

          const _arrFilter = [
            'desc',
            'orderBy',
            'limit',
            'page',
            'period',
            'team_ids',
            'operator_ids',
            'limit',
            'page',
            'search',
            'statuses',
            'issue_id'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'period':
                  this.dataSelectLastDayValue = options[_key];
                  break;
                case 'search':
                  this.dataSearch = options[_key];
                  break;
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
                case 'team_ids':
                  this.dataSelectTeamsValue = options[_key];
                  break;
                case 'issue_id':
                  this.dataSelectIssueValue = options[_key];
                  break;
                case 'operator_ids':
                  this.dataSelectOperatorValue = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
                case 'statuses':
                  this.filterPhone = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({
        desc = this.dataDesc,
        orderBy = this.dataOrderBy,
        teamId = this.dataSelectTeamsValue,
        issuesId = this.dataSelectIssueValue,
        limit = null,
        page = null,
        reset = false,
        search = this.dataSearch
      } = options) => {
        if (!this.dataSelectLastDayValue) {
          this.dataSelectLastDayValue = {
            ...this.dataSelectLastDayValueDefault
          };
        }

        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        const [
          {
            payload: { list = [] },
            misc: { paging = {} } = {}
          }
        ] = await Promise.all([
          this.$store.dispatch(
            `call/${CALL_GET_LOGS}`,
            helper.clearObject({
              params: {
                desc,
                period: this.dataSelectLastDayValue?.key,
                order_by: orderBy,
                limit: _limit,
                page: _page,
                search,
                issue_id: issuesId?.id,
                team_ids: helper.clearObject([teamId?.id]),
                operator_ids: helper.clearObject([
                  this.computedIsOperator
                    ? this.computedCurrentUser?.id
                    : this.dataSelectOperatorValue?.id
                ]),
                statuses: this.filterPhone.reduce((itemA, itemB) => {
                  if (itemB.active) {
                    itemA.push(itemB.key);
                  }
                  return itemA;
                }, [])
              }
            })
          ),
          this.$_ajaxUserAll(teamId?.id),
          this.$_ajaxIssues(teamId?.id)
        ]);

        this.dataObjPagination = paging;
        this.dataCallLogList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router
          .replace({
            ...this.$router.currentRoute,
            query: {
              [_filterKey]: JSON.stringify(
                helper.clearObject({
                  desc,
                  search,
                  period: this.dataSelectLastDayValue,
                  orderBy,
                  team_ids: teamId,
                  issue_id: issuesId,
                  limit: _limit,
                  page: _page,
                  operator_ids: this.computedIsOperator
                    ? [this.computedCurrentUser]
                    : this.dataSelectOperatorValue,
                  statuses: this.filterPhone
                })
              )
            }
          })
          .catch(() => {});

        options = {};
      };
    })();

    this.$_ajaxIssues = (() => {
      const _cacheIssues = {};

      return async (teamId = null, categoryId = null) => {
        const key = `${String(categoryId ?? 0)},${String(teamId ?? 0)}`;
        if (Array.isArray(_cacheIssues[key])) {
          this.dataSelectOptionsIssues = cloneDeep(_cacheIssues[key]);
          return;
        }

        try {
          const {
            payload: { list = [] }
          } = await this.$store.dispatch(`issues/${ISSUES_GET}`, {
            params: {
              // category_ids: [categoryId],
              team_ids: [teamId]
            }
          });

          _cacheIssues[key] = list;
          this.dataSelectOptionsIssues = cloneDeep(list);
        } catch (e) {
          console.log(e);
        }
      };
    })();

    this.$_ajaxUserAll = (() => {
      let _cacheAllUser = [];

      return async (idTeam = null) => {
        if (_cacheAllUser.length > 0 && !idTeam) {
          this.dataSelectOptionsOperator = _cacheAllUser;
          return;
        }

        try {
          const {
            payload: { list: listUser = [] }
          } = await this.$store.dispatch(`user/${USERS_GET_ALL}`, {
            params: {
              team_ids: idTeam ? [idTeam] : null,
              role: ID_ROLE_OPERATOR,
              short: true
            }
          });
          if (idTeam) {
            this.dataSelectOptionsOperator = listUser;
          } else {
            _cacheAllUser = cloneDeep(listUser);
            this.dataSelectOptionsOperator = listUser;
          }
        } catch (e) {
          console.log(e);
        }
      };
    })();

    const [
      {
        payload: { list: listTeam = [] }
      }
    ] = await Promise.all([
      this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
        params: {
          short: true
        }
      }),
      this.$_ajaxUserAll(),
      this.$_ajax(),
      this.$_ajaxIssues()
    ]);

    this.dataSelectOptionsTeam = listTeam;
  },
  methods: {
    onSearch: debounce(function () {
      this.$_ajax({ reset: true });
    }, 400),
    clickFilterSend () {
      this.dataShowPopover = false;
      this.$_ajax({ reset: false });
    },
    clickFilterCancel () {
      this.filterPhone = this.filterPhone.map((item) => {
        item.active = false;
        return item;
      });
      this.dataShowPopover = false;
      this.$_ajax({ reset: true });
    },
    onSelectRefresh () {
      this.$_ajax({ reset: true });
    },
    onSelectTeams () {
      this.dataSelectOperatorValue = null;
      this.dataSelectIssueValue = null;
      this.$_ajaxIssues(this.dataSelectTeamsValue?.id);
      this.$_ajax({ reset: true });
    },
    methodTypeCall (value = '') {
      const [hour = 0, minutes = 0, seconds = 0] = value?.split(':') ?? [];
      const allDuration = +seconds + minutes * 1 * 60 + 1 * hour * 3600;

      if (allDuration === 0) {
        return 'danger';
      } else if (allDuration > 1 && allDuration < 20) {
        return 'warning';
      }
      return 'success';
    },
    onSortTabel ({ sortBy, sortDesc }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    // projects/60/tasks/1325
    async onActions (
      { task_id: taskId, project_id: projectId, player_id },
      action
    ) {
      switch (action) {
        case 'view':
          this.$router.push({
            name: 'ProjectTasksDetail',
            params: {
              taskId,
              projectId
            },
            query: {
              taskName: `ID: ${player_id}`
            }
          });
          break;
      }
    }
  }
};
</script>
